import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import router from './router'

Vue.use(Vuex)
Vue.use(VueResource)


export default new Vuex.Store({
  state: {
    tag: null
  },
  mutations: {
  },
  actions: {
    loadIndex(context, payload) {
      return new Promise((resolve, reject) => {
        window.instance.get(`index/${payload.language}`).then((response) => {
            if (response.data) {
              resolve(response.data);
            }
            else {
              router.push("/404");
              reject(false);
            }
          }
        );
      });
    },
    loadGallery(context, payload) {

      return new Promise((resolve, reject) => {
        payload = payload || {};
        payload.offset = payload.offset || 0;
        window.instance.get(`imgset${payload.tag ? `/${payload.tag}` : ''}?offset=${payload.offset}`).then((response) => {
            if (response.data) {
              response.data.forEach(element => {
                if (element.tags[0].type=='about') {
                  let _c = [
                    (element.captions[0] && element.captions[0] != "Caption" ? element.captions[0] : ""),
                    (element.captions[1] && element.captions[1] != "Caption" ? element.captions[1] : ""),
                    (element.captions[2] && element.captions[2] != "Caption" ? element.captions[2] : "")
                  ];
                  element.captions_parsed = [
                    _c[0].replace(/(?:\r\n|\r|\n)/g,' - '),
                    _c[1].replace(/(?:\r\n|\r|\n)/g,' - '),
                    _c[2].replace(/(?:\r\n|\r|\n)/g,' - ')
                  ]
                }
                else {
                  element.captions_parsed = element.captions
                }
                if (element.tags[0].type=='work') { 
                  var _t = element.tags[1];
                  element.tags[1] = element.tags[0]
                  element.tags[0] = _t;
                }
              });
              resolve(response.data);
            }
            else {
              router.push("/404");
              reject(false);
            }
          }
        );
      });
    },
    loadData(context, payload) {

      

      return new Promise((resolve, reject) => {
        payload.mode = payload.mode || 'contributions';
        payload.params = payload.params || {'populate': 'true'}
        window.instance.get(`api/${payload.mode}${payload.issue ? `/${payload.issue}` : ''}${payload.chapter ? `/${payload.chapter}` : ''}`, {
            params: payload.params}
          ).then((response) => {
            if (response.data && !response.data.Error) {
              resolve(response.data);
              if (payload.mutator) {
                context.commit(payload.mutator, response.data);
              }
            }
            else {
              router.push("/404");
              reject(false);
            }
          }
        );
      });
    }
  }
})
