<template>
  <div id="app">
    <Searchbar/>
    <Menu/>
    <News ref="newscomponent"/>
    <GalleryFullscreen/>
    <Sidebar/>
    <router-view name="content"></router-view>
    <Gallery/>
    <div class="consent" v-if="$root.consent === 'never'">
      <div class="consent-message consent-message-small" v-html="termText"/>
      <div class="consent-buttons consent-buttons-small">
        <a @click="decline()">{{
          [
          'Ablehnen',
          'Decline',
          'Decline'
          ][$root.languageid]
        }}</a>        
        <a @click="accept()">{{
          [
          'Akzeptieren',
          'Accept',
          'Accept'
          ][$root.languageid]
        }}</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "main";
  .filter-active {
    cursor: pointer;
    &:before {
      content: "✕ ";
    }
  }
  .consent {
    z-index: 100000;
    bottom: 0px;
    position: fixed;
    padding: $base-size;
    background: #FFF;
    border-top: 2px solid;
    width: 100%;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-message-small {
      a {
        border-bottom: 1px solid black;
      }
    }
    &-buttons {
      a {
        margin-left: $base-size;
        padding: calc(0.5 * #{$base-size}) #{$base-size};
        border: 1px solid;
        background: white;
        cursor: pointer;
        transition: all 0.125s;
        &:last-child {
          color: white;
          background: $gray;
          border-color: $gray;
        }
        &:hover {
          color: white;
            background: black;
            border-color: black;
        }        
      }
    }
  }

</style>

<script>
import Gallery from '@/components/Gallery.vue'
import GalleryFullscreen from '@/components/GalleryFullscreen.vue'
import Searchbar from '@/components/Searchbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Menu from '@/components/Menu.vue'
import News from '@/components/News.vue'


export default {
  name: 'home',
  components: {
    Gallery,
    GalleryFullscreen,
    Searchbar,
    Menu,
    News,
    Sidebar
  },
  computed: {
    termText() {
      return (
        [
          'Diese Website verwendet Cookies zur Erfolgs- und Reichweitenmessung. Weitere Informationen in unserer <a href="/De/Terms">Datenschutzerklärung</a>.',
          'We use cookies to analyze our website traffic. You can find further information in our <a href="/En/Terms">Privacy Policy</a>.',
          'We use cookies to analyze our website traffic. You can find further information in our <a href="/En/Terms">Privacy Policy</a>.'
          ][this.$root.languageid]
        )
    }
  },
  methods: {
    async _action(action) {
      let consent = await window.instance.get(`consent?consent=${action}`);
      this.$root.consent = consent.data.consent
    },
    accept() {
      if (this.$matomo) {
        this.$matomo.setConsentGiven()
        this.$matomo.rememberCookieConsentGiven()
      }
      this._action('accept')
    },
    decline() {
      if (this.$matomo) {
        this.$matomo.forgetConsentGiven()
        this.$matomo.forgetCookieConsentGiven()
      }
      this._action('decline')
    }
  }
}
</script>
