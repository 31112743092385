<template>
  <div class="gallery" ref="body" v-on:scroll="more()">
    <div v-if="gallery_history !== null && gallery !== null" class="gallery-container" :class="`gallery-container-${this.cols}`">
      <div class="gallery-column" ref="cols" v-bind:key="col" v-for="col in this.cols">
        <div 
          v-on:click="is_highlight === doc ? close() : highlight(doc)" 
          class="gallery-item"  
          :class="{'gallery-item-active': is_highlight === doc, 'gallery-item-inactive': is_highlight !== doc && is_highlight}" 
          :style="{paddingBottom: `${doc.padding}%`}" 
          v-bind:key="index"  
          v-for="(doc, index) in gallery[cols - 1][col - 1]"
        >
          <a v-on:click.stop="$root.fullscreen( gallery[cols - 1], col - 1, index)" class="icon icon-absolute icon-top icon-right icon-fullscreen"></a>
          <img class="gallery-image gallery-image-foreground" :src="doc.image" />
          <div class="gallery-image gallery-image-background"></div>
          <p class="caption caption-small" v-if="doc.captions_parsed[$root.languageid]" v-html="doc.captions_parsed[$root.languageid]"></p>
        </div>
      </div>
    </div>
    
    <transition name="gallery-tag-active-fade">
      <div class="gallery-tag-active" v-if="tag_active && tag">
        <router-link 
          class="tag-active  tag tag-inverted tag-medium tag-bullet tag-bullet-active  tag-inverted-alert" 
          :to="{ name: 'tag', params: { type: null, filterid: null}}"
          v-html="tag_active.translation ? tag_active.translation[$root.languageid] : `!${tag}`">
        </router-link>            
      </div>
    </transition>
    
 

    <transition name="gallery-tag-active-right-fade">

      <div class="gallery-tag-active gallery-tag-active-right" v-if="tag_active.type == 'work' || tag_active.type == 'about' ||  tag_active.type == 'workshop'">

            <router-link
              class="tag tag-medium tag-sidebar-right" 
              :to="{ name: tag_active.href[0], params: { lang: $root.language[$root.languageid], type: tag_active.href[0], view: tag_active.href[0], name: $root.u(tag_active.href[1]),  id: tag_active.href[2] }}"
              v-html="['Weiterlesen','Read more', '更多信息'][$root.languageid]"
              >
            </router-link>

      </div>
      

      <div class="gallery-tag-active gallery-tag-active-right" v-else-if="tag_active && tag_active.href">
        <router-link 
          class="link link-transparent link-inverted link-active link-sidebar link-inverted-alert" 
          :to="{ name: 'sidebar', params: { lang: $root.language[$root.languageid], type: 'sidebar', name: $root.u(tag_active.href[1]),  id: tag_active.href[2] }}"
        >
        </router-link>


      </div>
    </transition>

    <transition name="gallery-footer-fade">
      <div v-if="is_highlight" class="gallery-footer">
          <div class="gallery-footer-content">
            <router-link 
              class="tag  tag-medium tag-bullet  tag-inverted-alert-dark" 
              :class="{'tag-inverted tag-active': tg.filterid==tag}"
              v-bind:key="index" 
              v-for="(tg, index) in is_highlight.tags" 
              :to="{ name: 'tag', params: { type: tg.filterid==tag ? null : tg.type, filterid: tg.filterid==tag ? null : tg.filterid}}"
              v-html="tg.translation ? tg.translation[$root.languageid] : `!${tg.filterid}`">
            </router-link>            
          </div>
          <!--
          <div class="gallery-footer-content gallery-footer-content-right" v-if="is_highlight.more">
            
            <router-link
              class="link link-medium link-sidebar" 
              :to="{ name: 'sidebar', params: { lang: $root.language[$root.languageid], type: 'sidebar', name: $root.u(link.href[1]),  id: link.href[2] }}"
              >
              &nbsp;
            </router-link>

            <router-link
              v-if="tag_active && is_highlight.more.href[2] === tag_active.href[2]"
              class="tag tag-medium tag-sidebar-right" 
              :to="{ name: is_highlight.more.href[0], params: { lang: $root.language[$root.languageid], type: is_highlight.more.href[0], view: is_highlight.more.href[0], name: $root.u(is_highlight.more.href[1]),  id: is_highlight.more.href[2] }}"
              v-html="['Weiterlesen','Read more', '更多信息'][$root.languageid]"
              >
            </router-link>

            <a  v-on:click.stop="close"
                style="cursor: pointer; margin-left: 1em;"
                class="link-large link-close"></a>
          </div>
          -->
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';

Vue.use(AsyncComputed);

export default {
  data () {
    return {
      offset: 0,
      tag: this.$route.params.filterid || false,
      tag_active: false,
      tag_history: this.$route.params.filterid || false,
      gallery_history: null,
      breakpoints: [0, 412, 768],
      cols: 1,
      loading: false,
      //tags: false,
      //link: false,
      is_highlight: null
    }
  },
  props: {
    translation: {
      type: Array
    }
  },
  computed: {
    storeState () {
      return this.$store.state.tag;
    }
  },
  asyncComputed: {
    async gallery() {
      this.loading = true;
      let _slice = await this.$store.dispatch('loadGallery', {offset: this.offset, tag: this.tag, lang: this.$root.languageid});
      if (this.tag !== this.tag_history || this.gallery_history === null) {
        this.gallery_history = [[[]],[[],[]],[[],[],[]]];
      }
      _slice.forEach((element, key) => {
        if (this.tag) {
          element.tags.forEach(_e => {
            if (_e.filterid * 1 === this.tag * 1) {
              this.tag_active = _e;
            }
          });
        }


        element.padding = 100 / element.size.width * element.size.height;
        this.gallery_history[0][0].push(element);
        this.gallery_history[1][key % 2].push(element)
        this.gallery_history[2][key % 3].push(element)
      });
      this.loading = false;
      this.tag_history = this.tag;
      return this.gallery_history;
    }
  },
  created () {
    window.addEventListener('resize', this.resize)
    let _self = this;
    this.$root.$on('reset', function () {
      _self.tag = null;
      _self.close();
    });
    this.resize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      this.cols = 1;
      this.breakpoints.forEach((bp, key) => {
        if (window.innerWidth > bp) this.cols = key + 1;
      });
    },
    more () {
      let _minheight = false;
      this.$refs.cols.forEach(c => {
        if (_minheight === false || c.offsetHeight < _minheight) {
          _minheight = c.offsetHeight;
        }
      })
      if (this.$refs.body == null || this.loading === true) return;
      let _remaining = (_minheight - (this.$refs.body.scrollTop + this.$refs.body.offsetHeight))
      if (_remaining < window.innerHeight / 2 && !this.loading) { 
        this.loading = true;
        this.offset += 1;
      }
      this.close();
    },
    highlight(doc) {
      this.is_highlight = doc;
      //this.tags = this.is_highlight.tags
      //this.link = this.tag_link ? {href:this.tag_link} : this.is_highlight.more
      this.$root.closeNews();
    },
    checkTag() {
      console.log(this.$store.tag);
    },
    setTag(to) {
      if (to.name === "tag") {
          this.tag = to.params.filterid
          //this.$store.tag = to.params.filterid
          //this.tag_link = null;
          this.tag_active = false;
          this.offset = 0;
          if (this.$refs.body)
            this.$refs.body.scrollTop = 0;
          this.close();
      }
    },
    close() {
      // Switch Footer off
      this.is_highlight = null;      
    }
  },
  watch:{
    $route: 'setTag',
    storeState (newCount, oldCount) {
      if (oldCount !== newCount &&  (this.tag * 1) !== newCount) {
        this.tag = newCount
        //this.tag_link = null;
        this.tag_active = false;
        this.offset = 0;
        if (this.$refs.body)
          this.$refs.body.scrollTop = 0;
        this.close();
      }
    }
  } 
}
</script>

<style lang="scss">
@import "../main";
.gallery {
  $gap: $margin-standard;
  z-index: 0;
  padding-top: $header-size;
  padding-bottom: $header-size * 3;
  width: 100%;
  overflow-y: auto;  
  height: 100%;
  position: absolute;
  -webkit-overflow-scrolling: touch;

  &-container {
    z-index: 1;
    width: 100%;
    position: relative;
    padding-left: $gap / 2;
    padding-right: $gap / 2;
    &-3 {
      .gallery-column { 
        width: 33.33%;
      }
    }
    &-2 {
      .gallery-column { 
        width: 50%;
      }
    }
    &-1 {
      .gallery-column { 
        width: 100%;
      }
    }
}

  &-column { 
    width: 33.33%;
    padding-left: $gap / 2;
    padding-right: $gap / 2;
    float: left;
  }

  &-item {
    width: 100%;
    line-height: 0;
    position: relative;
    margin-bottom: $gap;
    transition: $standard-transition;
    will-change: opacity;
    cursor: pointer;
    opacity: 1;
    .icon {
      transition: $standard-transition;      
      opacity: 0;
      @include sm() {
        display: none;
      }
    }
    .caption {
      transition: $standard-transition;      
      opacity: 0;
      position: absolute;
      bottom: 0em;
      left: 0em;
      z-index: 2;
      padding: $gap;
      background: rgba(0,0,0,0.25);
      color: white;
      width: 100%;
      max-height: 5.5em;
      overflow-x: hidden;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    &-inactive {
      opacity: 0.5;
      .caption,
      .icon {
        display: none;
      }
    }
    &-active, &:hover {
      .icon {
        opacity: 0.75;
      }
      .caption {
        opacity: 1;
      }
    }
  }
 
  &-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 1;
    &-background {
      background-color: $light-gray;
      z-index: 1;
      background-size: 2em !important;
      background-image: url('/img/spinner.svg');
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.75;
    }
  }

  &-tag-active {
    position: fixed;
    bottom: $margin-standard;
    left: $margin-standard;
    @include sm() {
      left: $margin-standard / 1.25;
    }
    z-index: 1;
    &-fade {
      &-enter-active,
      &-leave-active {
        transition: $standard-transition;
      }
      &-enter, &-leave-to {
        transform: translateX(-100%);
      }
    }
    &-right {
      right: ($margin-standard * 1.5);
      left: auto;
      bottom: $margin-standard ;
      .link-fullpage,
      .link-sidebar {
        &:after {
          border-width: 2px;
        }
      }
      &-fade {
        &-enter-active,
        &-leave-active {
          transition: $standard-transition;
        }
        &-enter, &-leave-to {
          transform: translateX(100%);
          .link-fullpage,
          .link-sidebar {
            &:after {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
    a {
      white-space: nowrap;
      vertical-align: middle;
    }
  }
}

</style>