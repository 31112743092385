<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <transition name="content-fade">
    <div class="content" v-if="active">
        <transition name="content-loading-fade">
            <div class="content-loading" v-if="loading"></div>
        </transition>
        <div class="content-page-narrow content-padding-bottom-double"  v-if="post">
            <div class="swiper">
                <swiper ref="mySwiper"  :options="$root.swiperOption">
                  <swiper-slide v-if="img.Files && img.Files.Resized && img.Files.Resized[2]" :data-caption="img.Captions[$root.languageid]"  :data-hash="`img-${$root.u(post.Contribution.Name)}-${index}`" v-bind:key="index"  v-for="(img, index) in post.Data.Bild.Content">
                      <div class="img" :class="img.Sizes[0] > img.Sizes[1] ? 'img-landscape' : 'img-portrait'">
                          <img class="img-container" :data-src="img.Files.Resized[2]"/>
                      </div>
                  </swiper-slide>
                  <div class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
                  <div class="swiper-button-next swiper-button-black" slot="button-next"></div>
                  <div class="swiper-pagination swiper-pagination-small"  slot="pagination"></div>
                </swiper>
            </div>
            <div class="block">
                <h1 v-html="post.Data._artistname"></h1>
                <h2>
                    <span  v-html="post.Data.Werktitel.Parsed[$root.languageid]"></span>
                    <span style="font-style:normal;"  v-if="post.Data.JahrDarstellung && post.Data.JahrDarstellung.Content" v-html="`, ${post.Data.JahrDarstellung.Content}`"></span>
                    <span style="font-style:normal;" v-else-if="post.Data.Jahr && post.Data.Jahr.Content">, {{post.Data.Jahr.Content | yearformat}}</span>
                </h2>
            </div>
            
            <div class="block block-padding-top">
                <div v-if="post.Data.Informationen.Parsed[$root.languageid]" class="lauftext lauftext-padding-bottom" v-html="post.Data.Informationen.Parsed[$root.languageid]"></div>
                <!--<div v-if="post.Data.Text.Parsed[$root.languageid]" class="lauftext" v-html="post.Data.Text.Parsed[$root.languageid]"></div>-->
            </div>


            <div class="block block-flex block-padding-bottom block-margin-top-double block-padding-top block-border-top-small">
                <div class="block-flex-content"  v-if="post.Data.Künstler.Reference">
                    <!--<p class="p-small p-margin-bottom">Künstler</p>-->
                    <router-link 
                        class="tag  tag-small" 
                        :to="{ name: 'Artist', params: { lang: $root.language[$root.languageid], type: 'Artist', id: post.Data.Künstler.Content[0] }}"
                        v-html="post.Data._artistname"
                    >
                    </router-link>
                </div>
                <div class="block-flex-content"  v-if="post.Data.Technik.Reference">                    
                    <!--<p class="p-small p-margin-bottom">Technik</p>-->
                    <router-link 
                        class="tag tag-small tag-margin-right" 
                        v-bind:key="key" 
                        :to="{ name: 'Technik', params: { lang: $root.language[$root.languageid], type: 'Technik', id: r }}"
                        v-for="(r, key) in post.Data.Technik.Content" 
                        v-if="post.Data.Technik.Reference[r].Bezeichnung"
                        v-html="post.Data.Technik.Reference[r].Bezeichnung.Parsed[$root.languageid]"
                    >
                    </router-link>
                </div>
                <div class="block-flex-content"  v-if="post.Data.Material.Reference">
                    <!--<p class="p-small p-margin-bottom">Material</p>-->
                    <router-link 
                        class="tag tag-small tag-margin-right" 
                        v-bind:key="key"
                        :to="{ name: 'Material', params: { lang: $root.language[$root.languageid], type: 'Material', id: r }}"
                        v-for="(r, key) in post.Data.Material.Content" 
                        v-if="post.Data.Material.Reference[r].Bezeichnung"
                        v-html="post.Data.Material.Reference[r].Bezeichnung.Parsed[$root.languageid]"
                    >
                    </router-link>
                </div>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>

// require styles
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      loading: false,
      post: null,
      error: null,
      active: false
    }
  },
  created () {
    if (this.active != this.$route.params.id) {
        this.error = this.post = null
        this.fetchData()
    }
  },
  watch: {
    '$route' () {
        if (this.active != this.$route.params.id) {
            this.post = null;
            this.fetchData()
        }
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      this.active = this.$route.params.id
      try {
        this.$store.dispatch('loadData', {mode: 'contribution', issue: this.$route.params.id}).then((data) => {
          //console.log(data)
          this.post = data
          try {
            this.post.Data._artist = this.post.Data.Künstler.Reference[this.post.Data.Künstler.Content];
            this.post.Data._artistname = 
              this.post.Data._artist.Name.Parsed[this.$root.languageid == 2 ? 3 : 1]
              + (this.post.Data._artist.Name.Parsed[this.$root.languageid == 2 ? 3 : 1] ? " " : "")
              + this.post.Data._artist.Name.Parsed[this.$root.languageid == 2 ? 2 : 0];
            
          } catch (error) {
            this.post.Data._artistname = "";
          }
        
          this.loading = false;
          this.$root.addBreadcrumb(this.post.Data.Werktitel.Parsed);

          this.post.Data._werktitel = this.post.Data.Werktitel.Content[this.$root.languageid]
          if (this.post.Data.JahrDarstellung && this.post.Data.JahrDarstellung.Content)
            this.post.Data._werktitel += `, ${this.post.Data.JahrDarstellung.Content}`
          else {
            var d = new Date(this.post.Data.Jahr.Content * 1000);
            this.post.Data._werktitel += `, ${d.getFullYear()}`
          }


          this.$root.applyMeta(
            `${this.post.Data._artistname} - ${this.post.Data._werktitel} - ${this.$root.metatags.title[this.$root.languageid]}`,
            `${this.post.Data._artistname}, ${this.post.Data.Werktitel.Content[this.$root.languageid]}, ${this.$root.metatags.title[this.$root.languageid]}`,
            `${this.post.Data._artistname}, ${this.post.Data._werktitel}, ${this.post.Data.Informationen.Content[this.$root.languageid]}`
          );

        });  
      } catch (error) {
        this.error = error;
      }
    }
  }  
}
</script>


<style lang="scss">

  @import "../main";
  @import "../slider";


.linkarray {
    a {
        margin-right: 1.5em;
        &:after {
            content: "/";
            position: absolute;
            margin-left: 0.5em;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
.content {
   &-fade {
    &-enter-active {
      transition: $standard-transition;
    }
    &-leave-active {
      transition: $standard-transition;
    }
    &-enter, &-leave-to {
      transform: translateY(100%);
    }
  }
}

.block-flex {
    &-content {
        margin-right: $margin-standard;
        margin-bottom: $margin-standard;
        display: inline-block;
    }
}

</style>