<template>
  <transition name="sidebar-fade">
    <div class="sidebar" v-if="$root.sidebar">
      <transition name="sidebar-content-fade">
        <div class="sidebar-content sidebar-hasfooter" v-if="post">
          
          <a class="icon icon-close icon-large icon-absolute icon-right icon-transparent" v-on:click="$root.sidebar = false"></a>
          
          <h1 v-html="post._title"></h1>
          
          <!--
          <img :src="post.Data.Bild.Content[post.ImageId ? post.ImageId : 0].Files.Resized[1]" v-if="(post.Contribution.FortemplateName == 'Material' || post.Contribution.FortemplateName == 'Technik') && post.Data.Bild.Content && post.Data.Bild.Content[post.ImageId ? post.ImageId : 0]">
          -->
          
          <!--
          <div v-if="post.Contribution.FortemplateName == 'Mitarbeiter' && post.ImageId" class="lauftext lauftext-medium lauftext-padding-bottom">
            <span v-html="post.Data.Bild.Content[post.ImageId].Captions[0]"></span>
            <br>
            <a :href="`mailto:${post.Data.Bild.Content[post.ImageId].Captions[1]}`" v-html="post.Data.Bild.Content[post.ImageId].Captions[1]"></a>
          </div>
          -->
          
          <!--
            <div v-if="post.Contribution.FortemplateName != 'Mitarbeiter' && post.ImageId" class="lauftext lauftext-medium lauftext-padding-bottom" v-html="post.Data.Bild.Content[post.ImageId].Captions[$root.languageid]"></div>
          -->
          
          <!--
          <div v-if="post.Data._artistname" class="lauftext lauftext-medium lauftext-padding-bottom">
            <router-link 
                v-bind:key="key" 
                :to="{ name: 'tag', params: { type: 'work',  filterid: post.Data.Künstler.Content }}"
                v-html="post.Data._artistname"
            >
            </router-link>  
          </div>
          -->


          <!--          
          <div v-if="post.Data.Informationen" class="lauftext lauftext-medium lauftext-padding-bottom" v-html="post.Data.Informationen.Parsed[$root.languageid]"></div>
          -->
          
          <div v-if="post.Data.Text && post.Data.Text.Parsed && post.Data.Text.Parsed[$root.languageid]" class="lauftext lauftext-medium lauftext-padding-bottom" v-html="post.Data.Text.Parsed[$root.languageid]"></div>
          
            
          <div v-if="post._bio || post.Data.Links" class="lauftext lauftext-padding-bottom-double lauftext-medium">
            <span v-if="post._bio" v-html="post._bio"></span>
            <br v-if="post._bio">
            <span v-if="post.Data.Links" class="linkarray">
                <a
                v-if="post.Data.Links.Parsed[0]" 
                target="_blank"
                :href="post.Data.Links.Parsed[0]"
                v-html="post.Data.Links.Parsed[0]">
                </a>
                <a
                v-if="post.Data.Links.Parsed[1]" 
                target="_blank"
                :href="post.Data.Links.Parsed[1]">
                    Twitter
                </a>        
                <a
                v-if="post.Data.Links.Parsed[2]" 
                target="_blank"
                :href="post.Data.Links.Parsed[2]">
                    Facebook
                </a>                                
                <a
                v-if="post.Data.Links.Parsed[3]" 
                target="_blank"
                :href="post.Data.Links.Parsed[3]">
                    Instagram
                </a>                                
            </span>
          </div>


          <!--
          <div v-if="post.Data.Techniken && post.Data.Techniken.Content && post.Data.Techniken.Content.length > 0" class="tabular tabular-bright tabular-relative">
            <table class="table-small">
              <thead>
                  <tr>
                      <th>    
                          Angewandte Techniken in dieser Werkstatt:
                      </th>
                      <th></th>
                  </tr>
              </thead>
              <router-link 
                  tag="tr"
                  v-bind:key="key" 
                  :to="{ name: 'sidebar', params: { lang: $root.language[$root.languageid], type: 'sidebar', name: $root.u(post.Data.Techniken.Reference[row].Bezeichnung.Content[$root.languageid]),  id: row }}"
                  v-for="(row, key) in post.Data.Techniken.Content"
              >
                      <td v-html="post.Data.Techniken.Reference[row].Bezeichnung.Content[$root.languageid]"></td>
                      <td></td>
              </router-link>  
            </table> 
          </div>    
          -->

          <div v-if="orderedUsers.length > 0" class="tabular tabular-bright tabular-relative">
            <table class="table-small">
              <thead>
                  <tr>
                      <th>    
                          <a :class="ordercol=='Data._sortartist' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data._sortartist'}">Name</a>
                      </th>
                      <th>
                          <a :class="ordercol=='Data.Werktitel' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Werktitel'}" v-html="['Titel', 'Title'][$root.languageid]"></a>
                      </th>
                      <th>
                          <a :class="ordercol=='Data.Jahr.Content' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Jahr.Content'}" v-html="['Jahr', 'Year'][$root.languageid]"></a>
                      </th>
                  </tr>
              </thead>
              <template v-for="(row, key) in orderedUsers">
                <router-link 
                    tag="tr"
                    v-bind:key="key" 
                    
                    :to="{ name: 'Work', params: { lang: $root.language[$root.languageid], type: 'Work', view: 'Work', name: $root.u(row.Contribution.Name),  id: row.Contribution.Id }}"

                    
                    v-if="row.Contribution.ForchapterName == 'Werke' && (row.Contribution.Status == 'Close' || loadstate == 'both')"
                >
                        <td v-if="row.Data.Artist" v-html="row.Data.Artist"></td>
                        <td v-else>–</td>
                        <td v-if="row.Data.Werktitel" v-html="`<i>${row.Data.Werktitel}</i>`"></td>
                        <td v-else>–</td>                      
                        <td v-if="row.Data.Jahr">{{row.Data.Jahr.Content | yearformat}}</td>
                        <td v-else>–</td>
                </router-link>  
              </template>
            </table> 
          </div>

        </div>
      </transition>
      <div class="sidebar-loading" v-if="loading">
      </div>
      <div class="sidebar-footer"  v-if="post">
        <div class="sidebar-footer-content">
          <a
            target="_blank"
            class="tag tag-medium tag-margin-bottom"
            :href="`//www.sitterwerk-katalog.ch/search?q=${post._title}`"
            v-html="['Suche im Sitterwerk Katalog','Search the Sitterwerk Catalogue', '在Sitterwerk目录中搜索'][$root.languageid]"
          >
          </a>
        </div>
        <!--<div class="sidebar-footer-content">
          <router-link
            class="tag tag-medium tag-margin-bottom"
            v-if="tagname && post.Contribution.Id != $store.tag"
            :to="{ name: 'tag', params: { type: tagname, filterid: post.Contribution.Id}}"
            v-html="['Bilder','Images', '图片'][$root.languageid]"
          >
          </router-link>     
        </div>-->
      </div>
    </div>
  </transition>     
</template>

<script>


import _ from 'lodash';

export default {
  data () {
    return {
      loading: true,
      post: null,
      error: null,
      active: false,
      ordercol: 'Data._sortartist',
      ordermode: 'asc',
      tagname: false,
      loadstate: window.instance.defaults.params['status']
    }
  },
  computed: {
    orderedUsers: {
        get: function () {
            return this.post.Contribution.ReferencedFrom ? _.orderBy(this.post.Contribution.ReferencedFrom, this.ordercol, this.ordermode) : null
        },
        set: function (value) {
            if (this.ordercol == value.order) {
                this.ordermode = this.ordermode == 'desc' ? 'asc' : 'desc'
            }
            else {
                this.ordercol = value.order
                this.ordermode = 'asc'
            }
        }
    }
  },  
  created () {
    if (this.$root.sidebar && this.$root.sidebar.meta.sidebar === true) {
      this.fetchData()
    }
  },
  watch: {
    '$root.sidebar' () {
      if (this.$root.sidebar && this.$root.sidebar.meta.sidebar === true)
        this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.active = true;
      this.error = this.post = null
      this.loading = true
      this.loadstate = window.instance.defaults.params['status']
      try {
        this.$store.dispatch('loadData', {mode: 'contribution', issue: this.$root.sidebar.params.id, params: {populate: true}}).then((data) => {
          data.Contribution.ReferencedFrom.forEach(element => {
            try {
              element.Data.Jahr.Content = 1*element.Data.Jahr.Content;  
            } catch (error) {
              element.Data.Jahr = {Content:  0};
            }
            try {
              element.Data.Werktitel = element.Data.Werktitel.Content[this.$root.languageid] || element.Data.Werktitel.Content[0] || "-";
            } catch (error) {
              element.Data.Werktitel = {Content:  ""};
            }
            try {
              if (element.Data.Künstler.Reference && element.Data.Künstler.Reference[element.Data.Künstler.Content]) {
                if (this.$root.languageid == 2) {
                  element.Data.Artist = [
                    element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[3],
                    element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[2]
                  ].join(" ")
                  element.Data._sortartist = element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[2];
                }
                else {
                  element.Data.Artist = [
                    element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[1],
                    element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[0]
                  ].join(" ")
                  element.Data._sortartist = element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[0];
                }

              }
              else element.Data.Artist = ""; 
            } catch (error) {
              element.Data.Artist = ""; 
            }
          })
          this.post = data

          

          switch (this.post.Contribution.Forchapter) {
            case 1:
              this.tagname = 'artist';
              break;
            case 2:
              this.tagname = 'work';
              break;
            case 5:
              this.tagname = 'material';
              break;
            case 6:
              this.tagname = 'tech';
              break;                
            case 8:
              this.tagname = 'about';
              break;     
            case 10:
              this.tagname = 'about';
              break;                
          }

          // Activat Tag in Gallery
          // this.$store.state.tag = this.post.Contribution.Id;


          this.post.ImageId = false;
          if (this.$root.sidebar.params.name) {
            let _id = this.$root.sidebar.params.name.match(/Image_(.*)/);
            if (_id && _id[1]) {
              this.post.ImageId = _id[1];
            }
          }


          this.loading = false;
          // Unify Data

          this.post._title = this.post.Data.Titel 
                             ? this.post.Data.Titel.Parsed[this.$root.languageid]
                               :this.post.Data.Werktitel 
                               ? `<i>${this.post.Data.Werktitel.Parsed[this.$root.languageid]}</i>${this.post.Data.JahrDarstellung && this.post.Data.JahrDarstellung.Content ? ', '+this.post.Data.JahrDarstellung.Content : (this.post.Data.Jahr && this.post.Data.Jahr.Content ? ', '+new Date(this.post.Data.Jahr.Content * 1000).getFullYear() : '')}`
                               : this.post.Data.Bezeichnung
                                 ? this.post.Data.Bezeichnung.Parsed[this.$root.languageid]
                                 : this.post.Data.Name
                                   ? this.post.Data.Name.Content[this.$root.languageid == 2 ? 3 : 1]
                                     + (this.post.Data.Name.Content[this.$root.languageid == 2 ? 3 : 1] ? " " : "")
                                     + this.post.Data.Name.Content[this.$root.languageid == 2 ? 2 : 0]
                                   : "";

          this.post._bio = this.post.Data.Name && this.post.Data.Name.Content ? this.post.Data.Name.Content[this.$root.languageid == 0 ? 5 : this.$root.languageid == 1 ? 6 : 7] : ""

            if (this.post.Data.Künstler && this.post.Data.Künstler.Reference) {
              this.post.Data._artist = this.post.Data.Künstler.Reference[this.post.Data.Künstler.Content];
              this.post.Data._artistname = 
                this.post.Data._artist.Name.Parsed[this.$root.languageid == 2 ? 3 : 1]
                + (this.post.Data._artist.Name.Parsed[this.$root.languageid == 2 ? 3 : 1] ? " " : "")
                + this.post.Data._artist.Name.Parsed[this.$root.languageid == 2 ? 2 : 0];
            }
        });  
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    }
  }  
}
</script>


<style lang="scss">

  @import "../main";
  .sidebar {
      position: absolute; 
      right: 0px;
      bottom: 0px;
      width: 400px;
      @include sm() {
        width: 100%;
      }
      top: 0px;
      z-index: 30;
      background: $gray;
      overflow: hidden;
      transform: translateX(0%);
      color: white;
      box-shadow: $shadow;      
      &-footer {
        position: absolute !important;
        bottom: 0px;
        height: $footer-size;
        width: 100%;
        background: rgba(0,0,0,.25);
        padding: $margin-standard;  
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &-loading {
        z-index: 1;
        background-size: 2em !important;
        background-image: url('/img/spinner.svg');
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0.75;
        width: 100%;
        height: 100%;
        position: absolute;
      }

      &-content {
        opacity: 1;
        padding: $margin-standard;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        bottom: $footer-size;
        img {
          display: block;
          width: 100%;
          margin-bottom: $margin-standard;
        }
        &-fade {
          &-enter-active {
              transition: $standard-transition;
          }
          &-leave-active {
              transition: $standard-transition;
          }
          &-enter, &-leave-to {
              opacity: 0;
          }
        }
      }

      h1 {
        margin-top: $margin-standard/ $button-height-factor;
        margin-bottom: $margin-standard;
//        line-height: $header-size / $button-height-factor;
      }

      .icon {
        line-height: $header-size / 2.4;
      }

      table > tr:hover {
        background: rgba($trans-gray, 0.1);
      }

      td, th {
        font-weight: normal;
        text-align: left;
        vertical-align: middle;
        a {
          margin-left: 1.2em;
          &:before {
            width: $icon-size / 3;
            height: $icon-size / 3;
            border-top: 1px solid white;
            content: " ";
            position: absolute;
            margin-left: -1em;
            margin-top: 0.7em;
        }      
          &.asc,
          &.desc {
            &:before {
              border-left: 1px solid white;
            }
          }
          
          &.desc {
            &:before {
              transform: rotate(-135deg);
              margin-top: 0.4em;
            }
          }
          &.asc {
            &:before {
              transform: rotate(45deg);
              margin-top: 0.6em;
            }
          }
        }

      }

      &-fade {
        &-enter-active {
            transition: $standard-transition;
        }
        &-leave-active {
            transition: $standard-transition;
        }
        &-enter, &-leave-to {
            transform: translateX(120%);
        }
      }
  }


</style>