<template>
    <transition name="gallery-fullscreen-fade">
      <div v-if="$root.is_fullscreen" class="gallery-fullscreen">
        <div class="swiper">
            <swiper :options="swiperOption" ref="mySwiper" >
              <swiper-slide v-bind:key="index"  v-for="(img, index) in $root.is_fullscreen">
                <div class="img img-fs">
                  <div class="img-container swiper-lazy" :data-background="img.image_large">
                    <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>    
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
              <div class="swiper-pagination swiper-pagination-small swiper-pagination-white"  slot="pagination"></div>
            </swiper>
            <div class="swiper-pagination swiper-pagination-small swiper-pagination-white swiper-pagination-white-left" v-html="caption"></div>
        </div>
        <a v-on:click.stop="$root.fullscreen(null)" class="icon-absolute icon-top icon-right searchbar-close searchbar-close-white">
          <span></span>
          <span></span>
        </a>
      </div>
    </transition>
</template>

<script>

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  }, 
  data() {
    return {
      caption: ''
    }
  },
  computed: {
    swiperOption: {
      get: function () {
        let self = this;
        return {
          spaceBetween: 20,
          loop: true,
          lazy: true,
          initialSlide: this.$root.is_fullscreen_index,
          pagination: {
              el: '.swiper-pagination',
              clickable: true,
              dynamicBullets: true,
              type: 'fraction'
          },
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
          },
          on: {
              slideChange() {
                self.currentIndex = this.activeIndex;
                try {
                   self.caption = ((self.$root.is_fullscreen[this.realIndex].captions[self.$root.languageid] && self.$root.is_fullscreen[this.realIndex].captions[self.$root.languageid] != 'Caption') ? self.$root.is_fullscreen[this.realIndex].captions[self.$root.languageid] : '')

                } catch (error) {
                  self.caption = '';
                }
              }
          }
        }
      }
    }
  }
}
</script>



<style lang="scss">
@import "../main";
@import "../slider";
.gallery-fullscreen {
    position: fixed;
    z-index: 30;
    backface-visibility: none;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: $trans-gray-dark;
    opacity: 1;
    transform: scale(1);
    will-change: transform, opacity;
    cursor: pointer;
    &-fade {
        &-enter-active {
        transition: $standard-transition;
        }
        &-leave-active {
        transition: $standard-transition;
        }
        &-enter, &-leave-to {
        opacity: 0;
        transform: scale(0);
        }
    }
    .swiper,
    .swiper-container {
      padding: 0em;
    }
    .swiper-pagination-white {
      color: white;
      padding: $margin-standard;
      &-right {
          left: auto;
          right: 0px;
          text-align: right;
      }
      &-left {
          left: 0px;
          right: auto;
          text-align: left;
      }
    }
    .swiper-slide {
      .img {
        width: 100%;
        height: 100vh;
        &-container {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
}

</style>