<template>
  <div class="navigation navigation-fixed" :class="{'navigation-fullscreen': hasfocus === true}">
    <a :class="{'menu-close': $root.showmenu || hasfocus || $root.breadcrumb.length > 0}" class="tglmenu menu menu-right menu-fixed scroll-visible" v-on:click="$root.breadcrumb.length > 0 ? $root.clearBreadcrumbs() : blur();">
            <span></span>
            <span></span>
            <span></span>
    </a>
    <ul>
        <li>
          <div class="navigation-input" id="searchbar">
            <transition name="navigation-input-icons-fade">
              <div class="navigation-input-icons" v-if="query==title && $root.breadcrumb.length === 0">
                <div class="navigation-input-lupe" v-on:click="focus()">
                  <div class="navigation-input-lupe-circle"></div>
                  <div class="navigation-input-lupe-bar"></div>
                </div>
                <div class="navigation-input-language">
                <!-- v-for="(tg, index) in ['De', 'En', '中文']" -->
                  <template v-for="(tg, index) in ['De', 'En']">
                    <a
                      v-if="index!=$root.languageid"
                      v-bind:key="index" 
                      v-on:click.stop="$root.changeLanguage(index)"
                      v-html="tg"
                      class="navigation-input-language-medium"
                      >
                    </a>
                  </template>
                </div>
              </div>
            </transition>
            <div   
              v-if="$root.breadcrumb.length === 0" 
              class="input-container"
            >
              <input ref="input" class="input-large" v-on:focus="focus()" type="text" :placeholder="placeholder" v-model="query">
            </div>
            <span 
              v-else
              class="tag tag-large tag-transparent tag-noborder tag-back" 
              v-on:click="$root.lastBreadcrumb()" 
              v-html="$root.breadcrumb[$root.breadcrumb.length-1].translation[$root.languageid]"
            >
            </span>
          </div>
        </li>
    </ul>
    <div class="navigation-index">
      <div class="navigation-index-container" v-if="index && hasfocus">

        <transition name="navigation-input-group-fade">
          <div class="navigation-input-group navigation-input-group-tophits"
              v-if="highlight"
              
          >
            <p v-html="['Top Treffer', 'Top Hits', '搜索结果'][$root.languageid]"></p>
            <router-link
              v-bind:key="key"
              class="navigation-index-small navigation-index-activated"
              v-for="(entry, key) in highlight"
              :to="{name: 'tag', params: {type: entry.path.type.toLowerCase(), filterid: entry.path.id}}"
            >
              <span v-on:click="blur();" v-html="entry.t"></span>
            </router-link>
          </div>
        </transition>



        <div class="navigation-input-group"
            v-bind:key="letter"
            v-for="(entries, letter) in index"
        >
          <p v-html="letter"></p>
          <template v-for="(entry, key) in entries">
            <router-link
              v-bind:key="key"
              class="navigation-index-small"
              :class="{'navigation-index-activated': indexMatch(entry.t)}"
              v-if="entry.t"
              :to="{name: 'tag', params: {type: entry.path.type.toLowerCase(), filterid: entry.path.id}}"
            >
              <span v-on:click="blur();" v-html="entry.t"></span>
            </router-link>
          </template>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

const _title = ['Kunstgiesserei St.Gallen', 'Art Foundry St.Gallen', '圣加仑雕塑艺术 有限公司']
const fuzzysort = require('fuzzysort');
import _ from 'lodash';

export default {
  data () {
    return {
      query: _title[this.$root.languageid],
      title: _title[this.$root.languageid],
      placeholder: _title[this.$root.languageid],
      index: false,
      loaded: false,
      hasfocus: false,
    }
  },
  computed: {
    highlight: function() {
      var _h = [];
      if (this.index !== false) {
        for (var key in this.index) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.index.hasOwnProperty(key)) {
            this.index[key].forEach((_d) => {
              const _r = fuzzysort.single(this.query, _d.t)
              if (_d.t && _r) {
                _d._score = _r.score
                _h.push(_d);
              }
            })
          }
        }
      }
      return _h.length > 0 ? _.orderBy(_h, '_score', 'desc').slice(0, 10) : false;
    }
  },
  watch: {
    '$root.languageid' ()  {
      this.query = _title[this.$root.languageid]
      this.title = _title[this.$root.languageid]
      this.placeholder = _title[this.$root.languageid]
    }
  },
  methods: {
    indexMatch(_t) {
      if (!this.query) return false;
      if (!_t) return false;
      return _t.match(new RegExp(this.query,'gi'))
    },
    focus() {
      this.query = ''; 
      this.loaded = false;
      this.hasfocus = true;
      this.placeholder = ['Suche…', 'Search…', '搜索'][this.$root.languageid];
      this.$refs.input.focus();
      try {
        this.$store.dispatch('loadIndex', {language: this.$root.languageid}).then(data => {
          this.index = data;
          console.log(this.index);
          for (var key in this.index) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.index.hasOwnProperty(key)) {
              this.index[key].forEach((_d) => {
                _d.t = _d.translation_formatted && _d.translation_formatted != "" ? _d.translation_formatted : _d.translation;
              })
            }
          }
          this.loaded = true;
        })
      } catch (error) {
          this.loaded = true;
          this.index = 'Error'
      }
    },
    blur() {
      // Search Mode
      if (this.hasfocus === true) {
        this.query = this.title; 
        this.hasfocus = false;
        this.placeholder = this.title;
        this.$root.showmenu = false;
      }
      // Menu Mode
      else {
        this.$root.showmenu = !this.$root.showmenu; 
        if (this.$root.showmenu === false) {
          this.$root.closeNews();
        }
      }
    }
  }
}
</script>

<style lang="scss">

@import "../main";

  .navigation {
    position: absolute;
    top: 0em;
    height: $header-size;
    box-shadow: $nav-shadow;
    background: $nav-background;
    z-index: 11;
    left: 0px;
    right: $margin-standard;
    overflow: visible;
    &-fullscreen {
      height: 100%;
      .navigation-index {
        transform: translateY(0%);
      }
    }

    
    



    &-index {
      transition: $standard-transition;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      margin-right: -$margin-standard;
      height: 100%;
      transform: translateY(-100%);
      background: $body-color;
      z-index: 1;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: $margin-standard;
      padding-top: $header-size;
      &-container {
        columns: 6;
        @include st() {
          columns: 4;
        }
        @include sm() {
          columns: 1;
        }
        a {
          display: block;
          text-indent: -1em;
          margin-left: 1em;
          @include sm() {
            display: none;
          }
        }
      }
      &-activated {
        text-decoration: underline;
        @include sm() {
          display: block !important;
          text-decoration: none;
          font-size: $body-font-size * $fac_largefont * 0.8 !important;
          line-height: $body-line-height * $fac_lineheight_largefont * 0.8 !important;
        }
      }
    }

    &-input {
      width: 100%;
      display: inline-block;
      padding: 0;
      margin: auto;
      height: $header-size / $button-height-factor;
      overflow: hidden;
      border: $navigation-border;
      background-color: $navigation-bgcolor;
      position: relative;
      display: block;
      z-index: 2;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      line-height:  $header-size / $button-height-factor;

      .input-container {
        position: absolute;
        left: $margin-standard / 2;
        right: 0px;
        bottom: 0px;
        top: 0px;
      }
      input {
        font-family: inherit;
        border: 0;
        margin: 0px;
        width: 100%;
        outline: none;
        caret-color: $gray;
        background: transparent;
        line-height: 1;
        padding: 0;
      }
      & > .tag {
        line-height: 1;
        vertical-align:initial;
      }
      &-language {
        position: absolute;
        right: $header-size / $button-height-factor;
        line-height: ($header-size / $button-height-factor) / 1.2;
        z-index: 2;
        a {
          transition: $standard-transition;
          margin-left: 1em;
          border-bottom: 1px solid;
          opacity: 0.3;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }
      &-icons {
        opacity: 1;
        transition: $standard-transition;
        &-fade {
          &-enter-active,
          &-leave-active {
            opacity: 1;
          }
          &-enter, &-leave-to {
            opacity: 0;
          }
        }
        @include sm() {
          display: none;
        }
      }
      &-lupe {
        $lupeheight: $header-size / $button-height-factor;
        $lupewidth: $header-size / $button-height-factor;
        position: absolute;
        right: 0px;
        top: 0px;
        height: $lupeheight;
        width: $lupewidth;
        cursor: pointer;
        z-index: 1;
        opacity: 0.3;
        transition: $standard-transition;
        &:hover {
          opacity: 1;
        }
        &-circle {
          height: $lupeheight * .6;
          width: $lupewidth  * .6;
          border-radius: 50%;
          border: 2px solid;
          position: absolute;
          right: $lupewidth * .1;
          top: $lupewidth * .1;
        }
        &-bar {
          height: 0px;
          width: $lupewidth  * .3;
          border-top: 2px solid;
          position: absolute;
          transform: rotate(-45deg);
          bottom: $lupewidth * .3;
          left: $lupewidth * .15;
        }
      }
    }

    &-input-group {
      margin-bottom: $margin-standard;
      @include sm() {
        margin-bottom: 0;
      }
      opacity: 1;
      p {
        text-align: center;
        margin-bottom: $margin-standard / 2;
        @include sm() {
          display: none;
        }
      }
      &-fade {
        &-enter-active {
          transition: $standard-transition;
          max-height: 100em;
          overflow: hidden;
        }
        &-leave-active {
          transition: $standard-transition;
          max-height: 0em;
          overflow: hidden;
        }
        &-enter, &-leave-to {
          opacity: 0;
          max-height: 0em;
        }
      }
      &-tophits {
        @include sm() {
          display: none;
        } 
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      margin-top: ($header-size - ($header-size / $button-height-factor)) / 2;      
      padding-left: $margin-standard / 2;
      padding-right: (($header-size / $button-height-factor) + $margin-standard);
    }
    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      width: 100%;
      padding: 0 $margin-standard / 2;
    }

    a.menu {
      $size: $header-size / $button-height-factor;
      display: block;
      width: $size;
      height: $size;
      position: relative;
      z-index: 2;
      cursor: pointer;
      &-sticky {
        z-index: 1000;
      }
      &-inline {
        display: inline-block;
      }
      &-right {
          position: absolute;
          top:  ($header-size - ($header-size / $button-height-factor)) / 2;
          right: 0;
      }
      span {
        display: block;
        width: $size;
        height: 0px;
        line-height: 0;
        position: absolute;
        transition: all 0.5s;
        &:before {
          content: " ";
          display: block;
          position: absolute;
          height: 2px;
          top: 0px;
          left: 0px;
          width: $size;
          background: $base-font-color;
          transition: all 0.5s;
        }
        &:first-child {
          transform: rotate(0deg) translate(0, 0);
          transform-origin: 0 0;
          &:before {
            margin-top: 1px;
          }
        }
        &:nth-child(2) {
          transform: translate(0, $size * .5);
          opacity: 1;
          &:before {
            margin-top: -1px;
          }
        }
        &:last-child {
          transform: rotate(0deg) translate(0, $size);
          transform-origin: 0 $size;
          &:before {
            margin-top: -3px;
          }
        }
      }
      &-closed {
        width: auto !important;
        height: auto !important;
        line-height: 0.25;
        &:after {
          font-size: 125%;
          content: "×";
          margin-left: 0.1em;
        }
      }
      &-close {
        span {
          &:first-child {
              &:before {
                  width: $size * 1.414213562373095;
              }
              transform: rotate(45deg) translate(0px, 0px);
              margin-left: 0px;
              margin-top: -1px;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
              &:before {
                  width: $size * 1.414213562373095;
              }
              transform: rotate(-45deg) translate(0, $size);
              margin-left: 0px;
              margin-top: 1px;
          }
        }
      }
      &-inverted {
        span:before {
          background: $body-color;
        }
      }
      &-tiny {
        font-size: $body-font-size * 0.4;
      }
    }
  }

</style>