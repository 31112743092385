import axios from "axios";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResource from 'vue-resource'
import VueMatomo from 'vue-matomo'
import "./filters/filters.js"
import PageNotFound from './views/PageNotFound.vue';




Vue.use(VueMatomo, {
  host: 'https://piwik.tr51.org',
  siteId: 23,
  router: router,
  enableLinkTracking: true,
  requireConsent: true,
  requireCookieConsent: true,
  trackInitialView: true,
  trackerFileName: 'matomo'
});

Vue.use(VueResource)
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.performance = true




window.instance = axios.create({
  baseURL: window.location.hostname.match(/localhost|192.168|10.0/g) ? 'http://localhost:8081/server' : '/server'
});

window.instance.defaults.params = {}
window.instance.defaults.params['status'] = window.location.hostname.match(/localhost|192.168|10.0|preview/g) ? 'both' : 'published';


async function initialize() {
  let response = await window.instance.get('token');
  window.instance.defaults.headers.common["X-API-KEY"] =  response.data;
  
  //Vue.prototype.$http = instance;
  // TOKEN Heartbeat every 10 Minutes
  setInterval(() => {
    window.instance.get('token').then((response) => {
      window.instance.defaults.headers.common["X-API-KEY"] =  response.data;
      console.log(window.instance.defaults.headers.common["X-API-KEY"]);
    });
  }, 10000);


  window.__vue__ = new Vue({
    router,
    store,
    data () {
      return {
        consent: null,
        loading: true,
        post: null,
        error: null,
        language: ['De', 'En', 'Cn'],
        languageid: 0,
        menu: {},
        foot: {},
        showmenu: false,
        is_fullscreen: null,
        is_fullscreen_index: null,
        breadcrumb: [],
        backwards: false,
        sidebar: false,
        swiperOption: {
          on: {
            click: function(e) {
              if (e.offsetX < e.target.offsetWidth / 2) {
                this.slidePrev();
              }
              else {
                this.slideNext();
              }

              
            },
            init: function () {
              try {
                let _c = this.slides[this.activeIndex].getAttribute('data-caption');
                window.__vue__.$nextTick(() => {
                  this.$el.find('.swiper-notification').html(_c != 'Caption' ? _c : "");                  
                }
                );
                
              } catch (error) {
                console.log("no caption")
              }
            },
            transitionEnd: function () {
              try {
                let _c = this.slides[this.activeIndex].getAttribute('data-caption');
                this.$el.find('.swiper-notification').html(_c != 'Caption' ? _c : "");
              } catch (error) {
                console.log("no caption")
              }              
            }
          },
          lazy: {
            loadPrevNext: true,
            elementClass: 'img-container'
          },
          spaceBetween: 20,
          loop: true,
          hashNavigation: {
            replaceState: true,
            watchState: true
          },
          pagination: {
              el: '.swiper-pagination',
              clickable: true,
              dynamicBullets: true,
              type: 'fraction'
          },
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
          }
        },
        yPos: {
          projectlist: 0
        },
        metatags: {
          title: [
            'Kunstgiesserei St. Gallen', 
            'Art Foundry St. Gallen'
          ],
          keywords: [
            'Kunstgiesserei St. Gallen, Kunstgiesserei, Art Foundry, Swiss Art Foundry, St. Gallen, Sitterwerk, Kesselhaus Josephsohn', 
            'Swiss Art Foundry, Art Foundry, Kunstgiesserei St. Gallen, Kunstgiesserei, St. Gallen, Sitterwerk, Kesselhaus Josephsohn'
          ],
          description: [
            'Die Kunstgiesserei St.Gallen ist eine Grosswerkstatt zur Realisierung von Kunstwerken. Von der modernen digitalen Umsetzung über das traditionelle Gusshandwerk und individuelle Sonderaufträge bis hin zu Restaurierungen setzen wir Ideen in Kunstwerke um.',
            'The Kunstgiesserei St.Gallen is a large art production workshop, which produces and restores plastic artworks on behalf of artists, galleries and museums.'
          ]
        }
      }
    },
    render: h => h(App),

    /** - Loading Routes from Rokfor
      * - Creating vue Routes
      **/

    beforeCreate: async function() {
      let views = {};
      let post = await store.dispatch('loadData', {mode: 'books'});
      post.Books.forEach(function(c){
        let _menu = false;
        let _hidden = false;
        let _route = false;
        let _foot = false;
        c.Options.forEach(function(co) {
          if (co.key == "menu" && co.value == "true") {
            _menu = true;
          }
          if (co.key == "hidden" && co.value == "true") {
            _hidden = true;
          }
          if (co.key == "footer" && co.value == "true") {
            _foot = true;
          }
          if (co.key == "route") {
            _route = co.value
          }        
        })
        if (_menu === true && _route !== false) {

          Vue.set(_foot === true ? this.foot : this.menu, c.Name, {
            name: _route,
            id: c.Id,
            hidden: _hidden,
            issue: c.Issues[0],
            chapter: c.Chapters[0],
            translation: [c.Localization[0].translation, c.Localization[1].translation, c.Localization[2].translation]
          });
          
          views[_route] =  () => {
            return import(`./views/${_route}.vue`);
          }

          this.$router.addRoutes([{
            path: `/:lang(De|En|Cn)/:view(${_route})/:id?/:name?`,
            name: _route,
            meta: {
              translation: [c.Localization[0].translation, c.Localization[1].translation, c.Localization[2].translation],
              issue: c.Issues[0],
              chapter: c.Chapters[0]
            },
            components: {
              content: views[_route]
            }
          }])
        }
      }, this)
      this.$router.addRoutes([{
        path: "*",
        components: {
          content: PageNotFound
        }
      }])
    },
    created: function () {
      if (this.$route.params.lang) {
        switch (this.$route.params.lang) {
          case 'En':
            this.languageid = 1;
            break;
          case 'Cn':
            this.languageid = 2;
            break;      
          default:
            this.languageid = 0;
            break;
        }
      }
      this.applyStyle(this.generateStyleDef(this.languageid));
      this.applyMeta();
    },
    async mounted() {
      let consent = await window.instance.get('consent');
      this.consent = consent.data.consent
      console.log(this.consent)
      if (this.consent === 'accept') {
        setTimeout(() => {
          if (this.$matomo) {
            this.$matomo.setConsentGiven()
            this.$matomo.rememberCookieConsentGiven()
          }
        }, 1000)
      }
    },
    methods: {
      reset() {
        this.$emit('reset')
        this.$router.push('/');
      },
      u(string) {
        return string.replace(/[\W_]+/g, '_');
      },
      changeLanguage(l) {
        this.languageid = l;
        this.applyStyle(this.generateStyleDef(this.languageid));
      },
      closeNews() {
        this.$children[0].$refs.newscomponent.post = null;
      },
      generateStyleDef(id) {
        return `
          .caption_${id} {
            display: block !important;
          }
        `;
      },
      applyStyle(styleDef) {
        Array.from(document.querySelectorAll('[data-style-controlled]')).map(el => el.parentNode.removeChild(el));
        let style = document.createElement('style');
        style.setAttribute('data-style-controlled', '');
        style.type = 'text/css';
        document.head.appendChild(style);
        style.innerHTML = styleDef;
      },
      fullscreen(gallery, col, index) {

        if (gallery === null) {
          this.is_fullscreen = gallery;
          return;
        }

        // Flatten Cols
        let _cumulated_index = index;
        let _cumulated_cols = [];

        for (let _c = 0; _c < gallery.length; _c++) {
          _cumulated_cols = _cumulated_cols.concat(gallery[_c]);
        }

        for (let _c = 0; _c < col; _c++) {
          _cumulated_index += gallery[_c].length;
        }

        this.is_fullscreen = _cumulated_cols;
        this.is_fullscreen_index = _cumulated_index;
      },
      addBreadcrumb (name) {
        if (!this.backwards)
        {
          this.breadcrumb.push({
            route: this.$route.path,
            translation: name
          });
        }
        this.backwards = false;
      },
      clearBreadcrumbs () {
        this.breadcrumb = [];  
        this.$router.push({ name: 'home'});
      },
      lastBreadcrumb () {
        this.showmenu = false
        if (this.breadcrumb.length > 1) {
          this.breadcrumb.splice(-1);
          this.$router.go(-1);
          this.backwards = true;
        }
        else  {
          this.clearBreadcrumbs();
        }
      },
      applyMeta(title, keywords, description) {
        title = title || this.metatags.title[this.languageid];
        keywords = keywords || this.metatags.keywords[this.languageid];
        description = description || this.metatags.description[this.languageid];

        let identifier = 'data-meta-controlled';
        Array.from(document.querySelectorAll(`[${identifier}]`)).map(el => el.parentNode.removeChild(el));
        
        let _title = document.createElement('title');
        _title.setAttribute(identifier, '');
        _title.innerHTML = title;
        document.head.appendChild(_title);

        let _keywords = document.createElement('meta');
        _keywords.setAttribute('name', 'keywords');
        _keywords.setAttribute('content', keywords);
        _keywords.setAttribute(identifier, '');
        document.head.appendChild(_keywords);

        let _description = document.createElement('meta');
        _description.setAttribute('name', 'description');
        _description.setAttribute('content', description);
        _description.setAttribute(identifier, '');
        document.head.appendChild(_description);


        let _hreflang_de = document.createElement('link');
        let _link_de = window.location.href.replace(/\/De\/|\/En\//,'/De/');
        _hreflang_de.setAttribute('rel', 'alternate');
        _hreflang_de.setAttribute('href',  _link_de);
        _hreflang_de.setAttribute('hreflang', 'de');
        _hreflang_de.setAttribute(identifier, '');
        document.head.appendChild(_hreflang_de);

        let _hreflang_en = document.createElement('link');
        let _link_en = window.location.href.replace(/\/De\/|\/En\//,'/En/');
        _hreflang_en.setAttribute('rel', 'alternate');
        _hreflang_en.setAttribute('href',  _link_en);
        _hreflang_en.setAttribute('hreflang', 'en');
        _hreflang_en.setAttribute(identifier, '');
        document.head.appendChild(_hreflang_en);

        Array.from(document.querySelectorAll('[data-language-controlled]')).map(
          el => el.setAttribute('lang',  this.language[this.languageid])
        );
      }
    },
    watch: {
      '$route' (to, from) {
        if (this.$route.params.lang) {
          switch (this.$route.params.lang) {
            case 'Cn':
              this.languageid = 2;
              break;
            case 'En':
              this.languageid = 1;        
              break;
            default:
              this.languageid = 0;        
              break;
          }
        }
        this.applyStyle(this.generateStyleDef(this.languageid));
        if (this.$route.name == "language") {
          let _t = { name: from.name, params: from.params};
          _t.params.lang = to.params.lang;
          this.$router.push(_t);
        }
      }
    }
  }).$mount('#app')
}



initialize();