<template>
  <transition name="searchbar-fade">
    <div class="searchbar" v-if="post && show">
      <a class="searchbar-close"  v-on:click="post = null">
            <span></span>
            <span></span>
      </a>
      <template v-for="(row, key) in post.Documents">
        <div 
        v-bind:key="key" 
        :class="{'searchbar-document-expand' : row.foldout === true}" 
        class="searchbar-document" 
        v-if="(!$route.params.id && row.Data.Sprache.Content.indexOf(String($root.languageid)) !== -1) || $route.params.id == row.Contribution.Id"        
        >
          <router-link
            class="searchbar-document-title searchbar-document-title-large"
            :to="{name: 'news', params:{lang: $root.language[$root.languageid], news: 'News', id: row.Contribution.Id, name: $root.u(row.Contribution.Name)}}"
            v-html="row.Data.Titel.Parsed"
          >
          </router-link>  
          <div class="searchbar-document-date searchbar-document-date-medium">{{row.Data.Datum.Content | dateformat}}</div>
          <div class="searchbar-document-content lauftext">
            <figure class="searchbar-figure" v-bind:key="imgid" v-for="(img, imgid) in row.Data.Bild.Content">
              <img :src="img.Files.Resized[0]">
              <figcaption class="caption-small" v-html="img.Captions"></figcaption>
            </figure>
            <div v-html="row.Data.Teaser.Parsed"></div>
          </div>
          <template v-if="(row.Data.Download.Content && row.Data.Download.Content.length > 0) || row.Data.Text.Parsed">
            <transition name="searchbar-document-content-fold-fade">
              <div class="searchbar-document-content searchbar-document-content-fold" v-if="row.foldout === true || $route.params.id == row.Contribution.Id" >
                <div class="searchbar-document-content-column searchbar-document-content-medium lauftext" v-html="row.Data.Text.Parsed"></div>
                <div class="searchbar-download lauftext" v-if="row.Data.Download.Content && row.Data.Download.Content.length > 0">
                  <a 
                  class="searchbar-download-link searchbar-download-link-medium" v-bind:key="imgid" v-for="(img, imgid) in row.Data.Download.Content"
                  v-html="img.Captions"
                  target="_blank"
                  :href="img.Files.Original"
                  >
                  </a>
                </div>
              </div>
            </transition>
            <a class="searchbar-document-more" v-if="$route.params.id != row.Contribution.Id" v-on:click="row.foldout = !row.foldout">&nbsp;</a>
          </template>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>


export default {
  data () {
    return {
      post: null,
      error: null,
      show: null
    }
  },
  created () {
    if (this.$route.name == "home" || this.$route.name == "news") {
      this.fetchData()
    }
  },
  watch: {
    '$route' () {
      this.error = this.post = this.show = null
      if (this.$route.name == "news")
        this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      try {
        this.$store.dispatch('loadData', {mode: 'contributions', issue: 15, chapter: 11}).then((data) => {
          console.log(data);
          data.Documents.forEach(element => {
            element.foldout = false;
            if (element.Data.Sprache.Content.indexOf(String(this.$root.languageid)) !== -1) this.show = true;
          });
          this.post = data
          if (this.$route.params.id) this.show = true;
          this.loading = false;
        });  
      } catch (error) {
        this.error = error;
      }
    }
  }  
}
</script>

<style lang="scss">

@import "../main";

.searchbar {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: $header-size $margin-standard 0 $margin-standard;
  z-index: 2;
  background: $light-gray;
  box-shadow: $shadow;
  &-fade {
    &-enter-active,
    &-leave-active {
      transition: $standard-transition;
    }
    &-enter, &-leave-to {
      transform: translateY(-110%);
    }
  } 


  &-close {
    $size: $header-size / $button-height-factor;
    display: block;
    width: $size;
    height: $size;
    position: relative;
    z-index: 2;
    cursor: pointer;
    float: right;
    margin-left: $margin-standard;
    span {
      display: block;
      width: $size;
      height: 0px;
      line-height: 0;
      position: absolute;
      transition: all 0.5s;
      &:before {
        content: " ";
        display: block;
        position: absolute;
        height: 2px;
        top: 0px;
        left: 0px;
        width: $size;
        background: $base-font-color;
        transition: all 0.5s;
      }
      &:first-child {
        transform-origin: 0 0;
        transform: rotate(45deg) translate(0px, 0px);
        margin-left: 0px;
        margin-top: -1px;
        &:before {
          margin-top: 1px;
          width: $size * 1.414213562373095;
        }
      }
      &:last-child {
        transform-origin: 0 $size;
        transform: rotate(-45deg) translate(0, $size);
        margin-left: 0px;
        margin-top: 1px;
        &:before {
          margin-top: -3px;
          width: $size * 1.414213562373095;
        }
      }
    }    
    &-white {
      span {
        &:before {
          background: white;
        }
      }
    }
  }

  &-document {
    margin: auto;
    max-width: 800px;
    padding-bottom: $margin-standard;
    margin-bottom: $margin-standard;
    border-bottom: 2px solid;
    width: 100%;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    &-content {
      margin-top: $margin-standard;
      overflow: hidden;
      &-column {
        border-top: 1px solid;
        padding-top: $margin-standard;
        columns: 2;
        @include sm() {
          columns: 1;
        }
      }
      &-fold {
        max-height: 2000px;
        &-fade {
          &-enter-active,
          &-leave-active {
            transition: $standard-transition;
          }
          &-enter, &-leave-to {
            max-height: 0px;
          }
        }      
      }
    }
    &-expanded {
      display: none;
    }
    &-more {
      cursor: pointer;
      display: block;
      position: relative;
      margin: auto;
      width: 1em;
      padding: 0.5em 1em;
          clear: both;

      &:after {
        display: block;
        content: " ";
        position: absolute;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
        width: $margin-standard;
        height: $margin-standard;
        left: $margin-standard * 0.5;
        top: $margin-standard;
        transition: $standard-transition;
      }
    }
    &-expand {
      .searchbar-document {
        &-expanded {
          display: block;
        } 
        &-more {
          &:after {
            transform: rotate(135deg);
          }
        }
      }
    }
  }
  &-download {
    border-top: 1px solid;
    margin-top: $margin-standard;
    padding-top: $margin-standard;
    &-link {
      border-bottom: 1px solid;
    }
  }
  &-figure {
    margin: 0;
    padding: 0px;
    float: right;
    margin-left: 1em;
    width: 25%;
    min-width: 200px;
    img {
      width: 100%;
    }
    @include sm() {
      width: 100%;
      float: none;
      margin-left: 0;
      margin-bottom: $margin-standard;
    }
  }
}

</style>