import Vue from 'vue'
import Router from 'vue-router'
import Work from './views/Work.vue';
import Workshop from './views/Workshop.vue';


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  /*scrollBehavior (to, from, savedPosition) {
    return false;
  },*/
  routes: [
    {
      path: '/',
      name: 'home',
    },
    {
      path: '/:lang(De|En|Cn)/:news(News)/:id?/:name?',
      name: 'news',
    },    
    {
      path: '/:lang(De|En|Cn)',
      name: 'language',
    },    
    {
      path: '/tags/:type?/:filterid?',
      name: 'tag'
    },
    {
      path: '/:lang(De|En|Cn)/:type(Work)/:id/:name?',
      name: 'Work',
      components: {
        content: Work
      }      
    },
    {
      path: '/:lang(De|En|Cn)/:type(Workshop)/:id/:name?/:subid?/:subname?',
      name: 'Workshop',
      components: {
        content: Workshop
      },
      meta: {
        translation: ['Werkstätten', 'Workshops', '']
      }
    },
    {
      path: '/:lang(De|En|Cn)/:type(sidebar)/:id/:name?',
      name: 'sidebar',
      meta: {
        sidebar: true
      }
    },
    {
      path: '/:lang(De|En|Cn)/:type(Material)/:id/:name?',
      name: 'Material',
      meta: {
        sidebar: true
      }
    },    
    {
      path: '/:lang(De|En|Cn)/:type(Technik)/:id/:name?',
      name: 'Technik',
      meta: {
        sidebar: true
      }
    },    
    {
      path: '/:lang(De|En|Cn)/:type(Artist)/:id/:name?',
      name: 'Artist',
      meta: {
        sidebar: true
      }
    }
  ]
})


router.beforeEach((to, from, next) => {
  //document.title = `Kunstgiesserei St. Gallen${to.params.view ? ' – ' + to.params.view : ''}${to.params.name ? ' – ' + to.params.name : ''}`;
  //Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if (to.meta.sidebar === true) {
    router.app.$nextTick(() => {
      router.app.$root.sidebar = to;
    });
  }
  else {
    router.app.$root.sidebar = false;
    return next();    
  }
})

export default router