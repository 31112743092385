<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="content content-workshop" v-if="active">
    <transition name="content-loading-fade">
        <div class="content-loading" v-if="loading"></div>
    </transition>


    <div class="content-fix-content content-fix-content-centered content-fix-content-centered-page-narrow" v-if="post">
        <div 
        class="content-fix-content-document content-padding-bottom-double  content-margin-bottom-double"
        >
          <div style="clear:both;">
            <div class="swiper" :class="{'swiper-disabled': post.Data.Bild.Content.length == 1}">
                <swiper :options="$root.swiperOption">
                  <swiper-slide 
                    :data-caption="img.Captions[$root.languageid]"  
                    :data-hash="`img-${$root.u(post.Contribution.Name)}-${index}`" 
                    v-bind:key="index"  
                    v-for="(img, index) in post.Data.Bild.Content"
                  >
                      <div class="img" :class="img.Sizes[0] > img.Sizes[1] ? 'img-landscape' : 'img-portrait'">
                          <img class="img-container" :data-src="img.Files.Resized[2]"/>
                      </div>
                  </swiper-slide>
                  <div v-if="post.Data.Bild.Content.length > 1" class="swiper-button-prev swiper-button-black" slot="button-prev"></div>
                  <div v-if="post.Data.Bild.Content.length > 1" class="swiper-button-next swiper-button-black" slot="button-next"></div>
                  <div v-if="post.Data.Bild.Content.length" class="swiper-pagination swiper-pagination-small"  slot="pagination"></div>
                </swiper>
            </div>
            <div class="block block-margin-top-double"  v-if="post.Data.Techniken.Reference">  
                <!--<p class="p-small p-margin-bottom">Techniken dieser Werkstatt</p>-->
                <router-link 
                    class="tag tag-medium tag-margin-right" 
                    v-bind:key="key" 
                    :to="{ name: 'Technik', params: { lang: $root.language[$root.languageid], type: 'Technik', id: r }}"
                    v-for="(r, key) in post.Data.Techniken.Content" 
                    v-if="post.Data.Techniken.Reference[r].Bezeichnung"
                    v-html="post.Data.Techniken.Reference[r].Bezeichnung.Parsed[$root.languageid]"
                >
                </router-link>
            </div>
            <div class="block block-padding-top">
                <div v-if="post.Data.Text.Parsed[$root.languageid]" class="lauftext" v-html="post.Data.Text.Parsed[$root.languageid]"></div>
            </div>
            <div class="block block-padding-top laufext" v-if="post.Data.Tabelle && post.Data.Tabelle.Content && post.Data.Tabelle.Content.length > 0">
              <table>
                <tbody>
                  <tr v-bind:key="tblk" v-for="(tbl, tblk) in post.Data.Tabelle.Content">
                    <td v-html="tbl[$root.languageid]"/>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="block block-padding-top"  v-if="post.Data.Unterseiten && post.Data.Unterseiten.Content && post.Data.Unterseiten.Content.length > 0">  
              <div 
              class="subpage subpage-border-top"
              v-for="(row, skey) in post.Data.Unterseiten.Content"
              v-bind:key="skey" 
              >
                <div class="subpage-head">
                  <p 
                    class="lauftext" 
                    v-if="post.Data.Unterseiten.Reference[row] && post.Data.Unterseiten.Reference[row].Bezeichnung && post.Data.Unterseiten.Reference[row].Bezeichnung.Parsed" 
                    v-html="post.Data.Unterseiten.Reference[row].Bezeichnung.Parsed[$root.languageid]"
                    v-on:click="toggleSub(skey)"
                  >

                  </p>
                </div>
                <div class="subpage-space" v-if="subvisible.indexOf(skey)!== -1">
                  <div class="subpage-left">
                    <div class="swiper" style="margin-top: 0.25em;" v-if="post.Data.Unterseiten.Reference[row].Bild.Content"  :class="{'swiper-disabled': post.Data.Unterseiten.Reference[row].Bild.Content.length == 1}">
                      <swiper :options="createOption(skey)">
                        <swiper-slide v-if="img.Files.Resized" :data-caption="img.Captions[$root.languageid]"  :data-hash="`img-${$root.u(post.Contribution.Name)}-${index}`" v-bind:key="index"  v-for="(img, index) in post.Data.Unterseiten.Reference[row].Bild.Content">
                            <div class="img" :class="img.Sizes[0] > img.Sizes[1] ? 'img-landscape' : 'img-portrait'">
                                <img class="img-container" :src="img.Files.Resized[2]"/>
                            </div>
                        </swiper-slide>
                        <div v-if="post.Data.Unterseiten.Reference[row].Bild.Content.length > 1" class="swiper-button-small swiper-button-prev swiper-button-black" slot="button-prev"></div>
                        <div v-if="post.Data.Unterseiten.Reference[row].Bild.Content.length > 1" class="swiper-button-small swiper-button-next swiper-button-black" slot="button-next"></div>
                        <div v-if="post.Data.Unterseiten.Reference[row].Bild.Content" :class="`swiper-pagination-${skey}`" class="swiper-pagination swiper-pagination-small"  slot="pagination"></div>
                      </swiper>
                    </div>
                  </div>    
                  <div class="subpage-right  subpage-medium">
                    <div v-if="post.Data.Unterseiten.Reference[row].Text.Parsed" class="lauftext" v-html="post.Data.Unterseiten.Reference[row].Text.Parsed[$root.languageid]"></div>
                  </div>    
                </div>    
              </div>
            </div>

          </div>
        </div>


    </div>
  </div>
</template>

<script>

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

export default {
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      loading: false,
      post: null,
      subvisible: [],
      row: null,
      error: null,
      active: false,
      swiperOption: {
        autoHeight: false, //enable auto height
        spaceBetween: 20,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created () {
    if (this.active != this.$route.params.id) {
        this.error = this.post = null
        this.fetchData()
    }
  },
  watch: {
    '$route' () {
        if (this.active != this.$route.params.id) {
            this.post = null;
            this.fetchData()
        }
    }
  },
  methods: {
    createOption(skey) {
      let _o = this.$root.swiperOption;
      _o.pagination.el = `.swiper-pagination-${skey}`;
      return _o;
    },
    toggleSub(key) {
      if (this.subvisible.indexOf(key)===-1) {
        this.subvisible.push(key);
      }
      else {
        this.subvisible.splice(this.subvisible.indexOf(key),1);
      }
    },

    fetchData () {
      this.loading = true
      this.active = this.$route.params.id
      try {
        this.$store.dispatch('loadData', {mode: 'contribution', issue: this.$route.params.id}).then((data) => {
          this.post = data
          this.loading = false;
          if (this.$route.params.subid) {
              this.$root.addBreadcrumb(this.post.Data.Unterseiten.Reference[this.$route.params.subid].Bezeichnung.Parsed);
              this.$root.applyMeta(`${this.post.Data.Unterseiten.Reference[this.$route.params.subid].Bezeichnung.Content[this.$root.languageid]} - ${this.$route.meta.translation[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)            
          }
          else if (this.$route.params.id) {
              this.$root.addBreadcrumb(this.post.Data.Bezeichnung.Parsed);
              this.$root.applyMeta(`${this.post.Data.Bezeichnung.Content[this.$root.languageid]} - ${this.$route.meta.translation[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)            

          }
          /*
          try {
            this.$root.addBreadcrumb(this.$root.menu[this.post.Contribution.ForissueName].translation);  
          } catch (error) {
            
          }*/
         

        });  
      } catch (error) {
        this.error = error;
      }
    }
  }  
}
</script>


<style lang="scss">

  @import "../main";
  @import "../slider";
  
  .content-workshop {
    .content-fix-menu {
      @include st() {
       border-top: $standard-border;
       margin-top: 2em;
      }
    }
    .subpage {
      &-space {
        margin-left: $margin-standard / -2;
        margin-right: $margin-standard / -2;
      }
      &-left {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding: 0 $margin-standard / 2;
        @include sm() {
            width: 100%;
        }
      }
      &-right {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding: 0 $margin-standard / 2;
        @include sm() {
            width: 100%;
        }
      } 
      &-head {
        padding: $margin-standard / 2 0;
        cursor: pointer;
      }     
      &:last-child {
        border-bottom: $standard-border;
      }
    }
  }


</style>