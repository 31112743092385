<template>
  <div class="content">
    <div class="content-page-narrow content-padding-bottom-double">
      <div class="block block-padding-top-double">
        <h1>Fehler / Error</h1>
      </div>
      <div class="block">
        <div class="lauftext lauftext-padding-top-double">
          <p>
          Die angeforderte Seite wurde nicht gefunden.<br>
          Bitte navigieren sie zurück zur <router-link :to="'/'">Startseite</router-link>.
          </p>
        </div>
        <div class="lauftext">
          <p>
          The requested page is not found.<br>
          Please navigate back to the <router-link :to="'/'">start page</router-link>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">

  @import "../main";

</style>
<script>
export default {
  data () {
    return {
    }
  },
  created () {
      let _noindex = document.createElement('meta');
      _noindex.setAttribute('name', 'robots');
      _noindex.setAttribute('content', 'noindex');
      _noindex.setAttribute('data-meta-controlled', '');
      document.head.appendChild(_noindex);
  },
  watch: {
  },
  methods: {
  }  
}
</script>