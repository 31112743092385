<template>
    <nav class="mainmenu" :class="{'mainmenu-fade': $root.showmenu}">
      <div class="top">
        <ul>
          <li><a><span class="link-large">&nbsp;</span></a></li>
          <template v-for="menu in $root.menu">
            <li v-bind:key="menu.id" v-if="!menu.hidden">
              <router-link
                :to="{name: menu.name, params:{lang: $root.language[$root.languageid], view: menu.name}}"
              >
                <span class="link-large" v-on:click="$root.showmenu = false" v-html="menu.translation[$root.languageid]"></span>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
      <div class="bottom">
        <div class="bottom-content">  
          <a
            class="link link-plain link-medium" 
            :class="{'link-active':index==$root.languageid}"
            v-bind:key="index" 
            v-for="(tg, index) in ['De', 'En']" 
            v-on:click="$root.changeLanguage(index)"
            v-html="tg"
            >
            <!-- v-for="(tg, index) in ['De', 'En', '中文']" -->
          </a>
        </div>
        <div class="bottom-content">        
            <router-link
              :to="{name: foot.name, params:{lang: $root.language[$root.languageid], view: foot.name}}"
              v-bind:key="foot.id" 
              v-for="foot in $root.foot"
              class="link-medium"
            >
              <span v-on:click="$root.showmenu = false" v-html="foot.translation[$root.languageid]"></span>
            </router-link>   
            <router-link
              :to="{name: 'news', params:{lang: $root.language[$root.languageid], news: 'News'}}"
              class="link-medium"
            >
              <span v-on:click="$root.showmenu = false" v-html="['Nachrichten', 'News', '新闻'][$root.languageid]"></span>
            </router-link>               
        </div>

      </div>
    </nav>
</template>

<script>


export default {
}
</script>

<style lang="scss">

@import "../main";

.mainmenu {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: $sidemenu-background;
  z-index: 10;
  transform: translateY(-100%);
  transition: $standard-transition;

  &-fade {
    transform: translateY(0%);
  }
  .bottom, .top {
    position: absolute;
    width: 100%;
    left: 0;
    overflow: hidden;
  }
  .bottom {
    bottom: 0em;
    height: $footer-size;
    border-top: $standard-border;
    line-height: $footer-size;
    padding: $margin-standard;
    a {
      float: left;
      margin-right: 1em;
      &:last-child {
        float: right;
        margin-right: 0em;
      }
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: $margin-standard;
    padding-right: $margin-standard;
    &-content {
      line-height: $footer-size;
    }
  }
  .top {
    top: 0px;
    bottom: 3em;
  }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0 $margin-standard;
      display: block;
      width: 100%;
      margin-top: ($margin-standard / 2) + ($header-size / $button-height-factor);      
    }
    li {
      display: inline-block;
      width: 100%;
      a {
        width: 100%;
        display: inline-block;
        padding: 0em;
        margin: auto;
        line-height: $header-size / $button-height-factor;
        overflow: hidden;
        border-bottom: $standard-border;
        border-bottom-color: #000;
        span {
          width: 100%;
          display: inline-block;
        }
      }
      &:first-child {
        a {
          
        }
      }
    }
    &-logo {
      float: left;
      width: 1em; 
      height: 1em;
      padding: 0;
      display: inline;
    }
}

</style>